$(document).ready(function() {
    AOS.init({
        duration: 2000
    });
    /* Open when someone clicks on the span element */

    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 900,
        once: true
    });

});

function openNav() {
  document.getElementById("myNav").style.width = "100%";
}

/* Close when someone clicks on the "x" symbol inside the overlay */
function closeNav() {
  document.getElementById("myNav").style.width = "0%";
}